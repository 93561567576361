import {RESERVATIONS} from "./generics/reservations";

export const batch = {
    "name": "AWS Batch",
    "steps": [
        {
            "name": "Job definition configuration",
            "dependsOn": [],
            "description": "What kind of jobs are you running, and what are the limits for your containers?",
            "selectionType": "single",
            "options": [
                {
                    "name": "Single-node job definition",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/create-job-definition.html",
                    "recommendationLevel": 0,
                    "type": "CHECK"
                },
                {
                    "name": "Multi-node parallel job definition",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/create-multi-node-job-def.html",
                    "recommendationLevel": 0,
                    "type": "CHECK"
                },
                {
                    "name": "vCPUs reserved for the container",
                    "id": "",
                    "description": "",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                },
                {
                    "name": "Memory limit for the container",
                    "id": "",
                    "description": "",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                }
            ],
        },
        {
            "name": "Compute environment (CE) configuration",
            "description": "What managed or unmanaged configuration are you using for your CE?",
            "dependsOn": [],
            "selectionType": "single",
            "options": [
                {
                    "name": "Managed Amazon EC2",
                    "id": "MNG_EC2",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/compute_environments.html#managed_compute_environments",
                    "type": "",
                    "recommendationLevel": 0
                },
                {
                    "name": "Unmanaged Amazon EC2",
                    "id": "UNMNG_EC2",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/compute_environments.html#unmanaged_compute_environments",
                    "type": "",
                    "recommendationLevel": 0
                },
                {
                    "name": "Managed AWS Fargate",
                    "id": "FARGATE",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/create-compute-environment.html#create-compute-environment-fargate",
                    "type": "",
                    "recommendationLevel": 0
                },
                {
                    "name": "Managed Amazon EKS",
                    "id": "EKS",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/compute-environments-eks.html",
                    "type": "",
                    "recommendationLevel": 0
                }
            ],
        },
        {
            "name": "Managed Amazon EC2 CE",
            "description": "What resources are you using to configure your Managed EC2 CE?",
            "dependsOn": ["MNG_EC2"],
            "selectionType": "multi",
            "options": [
                {
                    "name": "Spot",
                    "id": "SPOT",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/es_es/AWSEC2/latest/UserGuide/using-spot-instances.html",
                    "recommendationLevel": 0,
                    "type": "CHECK"
                },
                {
                    "name": "On-Demand",
                    "id": "ON-DEMAND",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/es_es/AWSEC2/latest/UserGuide/ec2-on-demand-instances.html",
                    "recommendationLevel": 0,
                    "type": "CHECK"
                },
                {
                    "name": "Min / max / desired vCPUs",
                    "id": "",
                    "description": "",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                },
                {
                    "name": "Allowed instance types",
                    "id": "",
                    "description": "If possible, specify the instance types that you are allowing AWS Batch to launch.",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                }
            ]
        },
        {
            "name": "Unmanaged Amazon EC2 CE",
            "description": "What resources are you using to configure your Unmanaged EC2 CE?",
            "dependsOn": ["UNMNG_EC2"],
            "selectionType": "single",
            "options": [
                {
                    "name": "Maximum vCPUs",
                    "id": "",
                    "description": "",
                    "href": "",
                    "type": "INPUT",
                    "recommendationLevel": 0
                },
            ],
        },
        {
            "name": "Managed AWS Fargate CE",
            "description": "What resources are you using to configure your Managed Fargate CE?",
            "dependsOn": ["FARGATE"],
            "selectionType": "singe",
            "options": [
                {
                    "name": "Fargate Spot Capacity",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/AmazonECS/latest/bestpracticesguide/ec2-and-fargate-spot.html",
                    "recommendationLevel": 0,
                    "type": "CHECK"
                },
                {
                    "name": "Maximum vCPUs",
                    "id": "",
                    "description": "",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                }
            ]
        },
        {
            "name": "Managed Amazon EKS CE",
            "description": "What resources are you using to configure your Managed EKS CEs?",
            "dependsOn": ["EKS"],
            "selectionType": "multi",
            "options": [
                {
                    "name": "Min / max vCPUs",
                    "id": "",
                    "description": "",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                },
                {
                    "name": "Allowed instance types",
                    "id": "",
                    "description": "If possible, specify the instance types that you are allowing AWS Batch to launch.",
                    "href": "",
                    "recommendationLevel": 0,
                    "type": "INPUT"
                }
            ]
        },
        {
            "name": "On-Demand allocation strategy",
            "description": "How are you telling AWS Batch to fulfill your On-Demand capacity?",
            "dependsOn": ["ON-DEMAND"],
            "selectionType": "single",
            "options": [
                {
                    "name": "BEST_FIT_PROGRESSIVE",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/allocation-strategies.html",
                    "type": "CHECK",
                    "recommendationLevel": 1
                },
                {
                    "name": "BEST_FIT",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/allocation-strategies.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
            ]
        },
        ...RESERVATIONS,
        {
            "name": "Spot allocation strategy",
            "description": "How are you telling AWS Batch to fulfill your Spot capacity?",
            "dependsOn": ["SPOT"],
            "selectionType": "single",
            "options": [
                {
                    "name": "SPOT_PRICE_CAPACITY_OPTIMIZED",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/allocation-strategies.html",
                    "type": "CHECK",
                    "recommendationLevel": 1
                },
                {
                    "name": "SPOT_CAPACITY_OPTIMIZED",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/batch/latest/userguide/allocation-strategies.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                }
            ]
        },
    ]
}