import {INSTANCE_TYPE_SELECTION} from "./generics/instance_type_selection";
import {PURCHASING_OPTIONS} from "./generics/purchasing_options";
import {ALLOC_STRATEGIES} from "./generics/alloc_strategies";
import {RESERVATIONS} from "./generics/reservations";
import {SPOT_SIGNALS} from "./generics/spot_signals";

export const ec2Fleet = {
    "name": "EC2 Fleet",
    "steps": [
        ...INSTANCE_TYPE_SELECTION,
        ...PURCHASING_OPTIONS,
        ...ALLOC_STRATEGIES,
        ...SPOT_SIGNALS,
        ...RESERVATIONS,
        {
            "name": "EC2 Fleet configuration",
            "description": "Which EC2 Fleet features and configurations are you using?",
            "dependsOn": [],
            "selectionType": "multi",
            "options": [
                {
                    "name": "EC2 Fleet request type",
                    "id": "What request type are you using to launch the EC2 Fleet?",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-fleet-request-type.html",
                    "type": "INPUT",
                    "recommendationLevel": 0
                },
                {
                    "name": "Capacity Blocks instances",
                    "id": "",
                    "description": "Capacity Blocks for ML allow you to reserve GPU instances on a future date to support you short duration machine learning (ML) workloads.",
                    "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-capacity-blocks.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "On-Demand backup",
                    "id": "",
                    "description": "If you have urgent, unpredictable scaling needs, we recommend that you specify alternative instance types for your On-Demand Instances, in the event that your preferred option does not have sufficient available capacity",
                    "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-fleet-on-demand-backup.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "Maximum price overrides",
                    "id": "",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-fleet-price-overrides.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                }
            ]
        }
    ]
}