export const RESERVATIONS = [
    {
        "name": "Instance reservations",
        "description": "Do you have any reserved instances or savings plans?",
        "dependsOn": ["ON-DEMAND"],
        "selectionType": "multi",
        "options": [
            {
                "name": "Savings plans",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/whitepapers/latest/cost-optimization-reservation-models/savings-plans.html',
                "type": "CHECK",
                "recommendationLevel": 0
            },
            {
                "name": "Reserved instances",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-reserved-instances.html',
                "type": "CHECK",
                "recommendationLevel": 0
            }
        ]
    }
]