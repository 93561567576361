import {Box, Button, Cards, Input} from "@cloudscape-design/components";
import {getItemColor} from "../../utils/workloadUtils";

export default function StepContent({step, optionSelectionDelegate, inputChangeDelegate, selectedOptions, inputValues}) {
    function getInputValue(inputName) {
        return inputValues !== undefined && inputName in inputValues? inputValues[inputName] : ''
    }
    
    function renderSectionContent(item) {
        return (
            <>
                {item.description !== '' &&
                    <Box variant={"p"} color={"text-body-secondary"}>{item.description}</Box>
                }

                {item.type === 'INPUT' &&
                    <div style={{marginTop: '12px', paddingBottom: '12px'}}>
                        <Input
                            inputMode={'text'}
                            type={'text'}
                            value={getInputValue(item.name)}
                            onChange={({ detail }) => inputChangeDelegate(step.name, item.name, detail.value)}
                        ></Input>
                    </div>
                }

                {item.href !== '' &&
                    <Box textAlign={"center"}>
                        <div className={'Line'}/>
                        <Button href={item.href} variant={"link"} target={'_blank'} iconName={'external'} iconAlign={"right"}>View documentation</Button>
                    </Box>
                }
            </>
        )
    }

    return (
        <Cards
            selectionType={step.selectionType}
            selectedItems={selectedOptions}
            onSelectionChange={({ detail }) => {
                    optionSelectionDelegate(step.name, detail?.selectedItems ?? [])
                }
            }
            trackBy={'name'}
            cardsPerRow={[
                {cards: 1},
                {minWidth: 500, cards: 2},
                {minWidth: 800, cards: 3},
                {minWidth: 1000, cards: 4}
            ]}
            items={step.options}
            cardDefinition={
                {
                    header: item => (
                        <div cardtype={item.type}>
                            <Box fontSize={"heading-s"} fontWeight={"bold"} color={getItemColor(item)}>{item.name}</Box>
                        </div>
                    ),
                    sections: [
                        {
                            id: 'content',
                            content: item => (
                                renderSectionContent(item)
                            )
                        }
                    ]
                }
            }
        ></Cards>
    )
}