import * as React from "react";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Box, Grid} from "@cloudscape-design/components";

export default function CustomHelpPanel() {
    return (
        <HelpPanel
            header={<h2>About the tool</h2>}
        >
            <div>
                <p>
                    EC2 Spot workload advisor provides an interactive form for you to specify how you are running your workloads and using EC2 Spot.
                </p>
                <p>
                    Once submitted, the form is received by your account team and domain specialists so that they have a better understanding of your cloud usage and can provide the best possible support and recommendations.
                </p>
                <Box variant={"awsui-key-label"}>
                    Color coding
                </Box>
                <p>
                    Throughout the form, different accent colors are used to represent how good of a choice is using a particular technology or configuration option in a given workload. The accent colors have the following meaning:
                </p>

                <Grid
                    gridDefinition={[{colspan: 1}, {colspan: 11}]}
                >
                    <div className={'DivAccentPositive'}></div>
                    <p style={{'wordBreak': 'break-word', 'marginTop': '4px', 'marginLeft': '10px', 'padding': '0'}}>The choice has an added benefit over the other options, and should be used regardless of the nature of the workload.</p>
                </Grid>
                <Grid
                    gridDefinition={[{colspan: 1}, {colspan: 11}]}
                >
                    <div className={'DivAccentNegative'}></div>
                    <p style={{'wordBreak': 'break-word', 'marginTop': '4px', 'marginLeft': '10px', 'padding': '0'}}>The option is discouraged as it will not provide the best user experience and/or ease of adoption.</p>
                </Grid>
            </div>
        </HelpPanel>
    )
}