export const ALLOC_STRATEGIES = [
    {
        "name": "On-Demand allocation strategy",
        "description": "What strategy are you following to fulfill your On-Demand capacity?",
        "dependsOn": ["ON-DEMAND"],
        "selectionType": "single",
        "options": [
            {
                "name": "Lowest price",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/autoscaling/ec2/userguide/allocation-strategies.html#on-demand-allocation-strategy',
                "type": "CHECK",
                "recommendationLevel": 0
            },
            {
                "name": "Prioritised",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/autoscaling/ec2/userguide/allocation-strategies.html#on-demand-allocation-strategy',
                "type": "CHECK",
                "recommendationLevel": 0
            }
        ]
    },
    {
        "name": "Spot allocation strategy",
        "description": "What strategy are you following to fulfill your Spot capacity?",
        "dependsOn": ["SPOT"],
        "selectionType": "single",
        "options": [
            {
                "name": "Price-capacity optimised",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/autoscaling/ec2/userguide/allocation-strategies.html#spot-allocation-strategy',
                "type": "CHECK",
                "recommendationLevel": 1
            },
            {
                "name": "Capacity optimised",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/autoscaling/ec2/userguide/allocation-strategies.html#spot-allocation-strategy',
                "type": "CHECK",
                "recommendationLevel": 0
            },
            {
                "name": "Lowest price",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/autoscaling/ec2/userguide/allocation-strategies.html#spot-allocation-strategy',
                "type": "CHECK",
                "recommendationLevel": 0
            }
        ]
    }
]