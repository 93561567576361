import {useNavigate} from "react-router-dom";

export const ButtonDeployment = (props) => {
    const navigate = useNavigate()

    return <a
        {...props}
        onClick={event => {
            event.preventDefault()
            navigate(event.target.pathname)
        }}
    ></a>
}