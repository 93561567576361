import {asg} from "./asg";
import {batch} from "./batch";
import {ec2Fleet} from "./ec2_fleet";
import {ecs} from "./ecs";
import {eks} from "./eks";
import {emr} from "./emr";
import {k8s} from "./k8s";
import {sagemaker} from "./sagemaker";

export const deployments = {
    'ASG': asg,
    'EC2_F': ec2Fleet,
    'BATCH': batch,
    'ECS': ecs,
    'EKS': eks,
    'EMR': emr,
    'K8S': k8s,
    'SAGEMAKER': sagemaker
}