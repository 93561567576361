import {workloads} from "../flow_config_files/workloads";
import {useState} from "react";
import {Box, Cards, ContentLayout, Grid, Header} from "@cloudscape-design/components";
import {ButtonDeployment} from "../customComponents/buttonDeployment";
import {deployments} from "../flow_config_files/deployments";


export const Content = () => {
    const [selectedWorkload, setSelectedWorkload] = useState([])

    function getWorkloadDeployments() {
        for (const i in workloads) {
            if (workloads[i].name === selectedWorkload[0].name) return workloads[i]['deploymentOptions']
        }
    }

    function renderWorkloadDeployments(workload, deployments) {
        return (
            <Grid
                gridDefinition={deployments.map(o => (
                    {colspan: 6}
                ))}
            >
                {deployments.map(o => (
                    renderWorkloadDeployment(workload, o)
                ))}
            </Grid>
        )
    }

    function renderWorkloadDeployment(workload, deployment) {
        let className = 'OptionNeutral'

        if (deployment.recommendationLevel === -1) className = 'OptionNegative'
        else if (deployment.recommendationLevel === 1) className = 'OptionPositive'

        return (
            <div>
                <div className={className}>
                    <ButtonDeployment
                        type={"button"}
                        className={'ButtonDeployment'}
                        href={encodeURI('/' + workload + '/' + deployment.id)}
                    >
                        {deployments[deployment.id].name}
                    </ButtonDeployment>
                </div>
                <Box variant={"p"} textAlign={"center"} color={"text-body-secondary"}>{deployment.description}</Box>
            </div>
        )
    }

    return (
        <ContentLayout
            disableOverlap={true}
            header={
                <Header
                    variant="h1"
                    description="Use this questionnaire to provide your account team and domain specialists with information about how you are using EC2 Spot in your workloads"
                >
                    <Box variant="h1" fontWeight="heavy" padding="n" fontSize={"heading-xl"} margin={{top: 'm'}}>
                        EC2 Spot workload advisor
                    </Box>
                </Header>
            }
        >
            <Box variant="h2" margin={{bottom: 'l', top: 'l'}}>
                What is your workload?
            </Box>

            <Cards
                selectionType={"single"}
                selectedItems={selectedWorkload}
                onSelectionChange={({ detail }) =>
                    setSelectedWorkload(detail?.selectedItems ?? [])
                }
                trackBy={'name'}
                cardDefinition={{
                    header: e => e.name,
                    sections:[
                        {
                            id: 'deployments',
                            header: '',
                            content: e => e.name !== selectedWorkload[0]?.name ? '' : <>
                                <Box variant="awsui-key-label" margin={{bottom: 's'}}>Select your deployment option</Box>
                                {renderWorkloadDeployments(e.name, getWorkloadDeployments())}
                            </>
                        }
                    ]

                }}
                cardsPerRow={[
                    {cards: 1},
                    {minWidth: 500, cards: 2},
                    {minWidth: 1000, cards: 3}
                ]}
                items={workloads}
            />

        </ContentLayout>
    );
}