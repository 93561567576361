export const CAS = [
    {
        "name": "Cluster Autoscaler configuration",
        "description": "How have you configured your node groups?",
        "dependsOn": ["CAS"],
        "selectionType": "multi",
        "options": [
            {
                "name": "Amount of node groups",
                "id": "",
                "href": "",
                "description": "How many node groups are you using?",
                "type": "INPUT"
            },
            {
                "name": "Instance types used",
                "id": "",
                "href": "",
                "description": "What instance types are you using in your node groups?",
                "type": "INPUT"
            },
        ]
    },
]