export const i18nStrings = {
    stepNumberLabel: stepNumber =>
        `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) =>
        `Step ${stepNumber} of ${stepsCount}`,
    skipToButtonLabel: (step, stepNumber) =>
        `Skip to ${step.title}`,
    navigationAriaLabel: "Steps",
    cancelButton: "Cancel",
    previousButton: "Previous",
    nextButton: "Next",
    submitButton: "Submit form",
    optional: "optional"
}