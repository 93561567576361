export const sagemaker = {
    "name": "Amazon Sagemaker",
    "steps": [
        {
            "name": "",
            "description": "",
            "dependsOn": [],
            "options": [
                {
                    "name": "",
                    "description": "",
                    "href": "",
                    "type": "",
                    "recommendationLevel": 0
                }
            ]
        }
    ]
}