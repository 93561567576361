export const SPOT_SIGNALS = [
    {
        "name": "Spot signals management",
        "description": "What Spot signals are you handling?",
        "dependsOn": ["SPOT"],
        "selectionType": "multi",
        "options": [
            {
                "name": "Instance interruption notice",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/spot-interruptions.html',
                "type": "CHECK",
                "recommendationLevel": 1
            },
            {
                "name": "Instance rebalance recommendation",
                "id": "",
                "description": "",
                "href": 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/rebalance-recommendations.html',
                "type": "CHECK",
                "recommendationLevel": 0
            }
        ]
    }
]