import {ALLOC_STRATEGIES} from "./generics/alloc_strategies";
import {copyConfig} from "../../utils/copy";

const CPY_ALLOC_STRATEGIES = copyConfig([ALLOC_STRATEGIES[1]])

CPY_ALLOC_STRATEGIES[0].dependsOn = ['ALLOC_STRATEGY']
CPY_ALLOC_STRATEGIES[0].options.push({
    "name": "Diversified across all pools",
    "id": "",
    "description": "",
    "href": 'https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-instance-fleet.html?icmpid=docs_emr_help_panel#emr-instance-fleet-allocation-strategy',
    "type": "CHECK",
    "recommendationLevel": 0
})

export const emr = {
    "name": "Amazon EMR",
    "steps": [
        {
            "name": "Cluster configuration",
            "description": "What configuration method are you using for the primary, core and task node groups?",
            "dependsOn": [],
            "selectionType": "single",
            "options": [
                {
                    "name": "Instance groups",
                    "id": "I_GROUPS",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-instance-group-configuration.html#emr-plan-instance-groups",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "Instance fleets",
                    "id": "I_FLEETS",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-instance-group-configuration.html#emr-plan-instance-fleets",
                    "type": "CHECK",
                    "recommendationLevel": 1
                }
            ]
        },
        {
            "name": "Instance groups configuration",
            "description": "How do your instance groups look like?",
            "dependsOn": ['I_GROUPS'],
            "selectionType": "multi",
            "options": [
                {
                    'name': "Amount of instance groups",
                    'id': '',
                    'description': 'How many instance groups are you using?',
                    'href': '',
                    'type': 'INPUT',
                    "recommendationLevel": 0
                },
                {
                    'name': "Instance types used",
                    'id': '',
                    'description': 'What instance types are you using?',
                    'href': '',
                    'type': 'INPUT',
                    "recommendationLevel": 0
                }
            ]
        },
        {
            "name": "Instance fleets configuration",
            "description": "How do your instance fleets look like?",
            "dependsOn": ['I_FLEETS'],
            "selectionType": "multi",
            "options": [
                {
                    'name': "Instance types used",
                    'id': '',
                    'description': 'What instance types are you using?',
                    'href': '',
                    'type': 'INPUT',
                    "recommendationLevel": 0
                },
                {
                    'name': "Apply allocation strategy",
                    'id': 'ALLOC_STRATEGY',
                    'description': '',
                    'href': 'https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-instance-fleet.html?icmpid=docs_emr_help_panel#emr-instance-fleet-allocation-strategy',
                    'type': 'CHECK',
                    "recommendationLevel": 1
                }
            ]
        },
        {
            "name": "Cluster scaling configuration",
            "description": "How are you scaling the core and task nodes in your cluster?",
            "dependsOn": [],
            "selectionType": "single",
            "options": [
                {
                    'name': "Cluster size set manually",
                    'id': '',
                    'description': '',
                    'href': 'https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-manage-resize.html?icmpid=docs_emr_help_panel',
                    'type': 'CHECK',
                    "recommendationLevel": 0
                },
                {
                    'name': "EMR-managed scaling",
                    'id': '',
                    'description': '',
                    'href': 'https://docs.aws.amazon.com/emr/latest/ManagementGuide/emr-managed-scaling.html?icmpid=docs_emr_help_panel',
                    'type': 'CHECK',
                    "recommendationLevel": 1
                }
            ]
        },
        ...CPY_ALLOC_STRATEGIES
    ]
}