import {Content} from "./content"
import {AppLayout, BreadcrumbGroup} from "@cloudscape-design/components";
import CustomHelpPanel from "../customComponents/customHelpPanel";
import {useNavigate, useParams} from "react-router-dom";
import {deployments} from "../flow_config_files/deployments";

export const WorkloadWizard = () => {
    const navigate = useNavigate()
    const workload = useParams()['workload']
    const deployment = useParams()['deployment']

    return (
        <AppLayout
            breadcrumbs={
                <BreadcrumbGroup
                    items={
                        [
                            {text: 'Workload selection', href: '/'},
                            {text: workload, href: '/'},
                            {text: deployments[deployment].name, href: '/'},
                        ]
                    }
                    onClick={event => {
                        if (!event.detail.external) {
                            event.preventDefault();
                            navigate(event.detail.href)
                        }
                    }}
                >
                </BreadcrumbGroup>
            }
            navigationHide={true}
            tools={<CustomHelpPanel/>}
            content={<Content/>}
            disableContentPaddings={false}
            contentType={"wizard"}
        />
    )
}