export const PURCHASING_OPTIONS = [
    {
        "name": "Purchasing options",
        "description": "What EC2 purchasing options are you using?",
        "dependsOn": [],
        "selectionType": "multi",
        "options": [
            {
                "name": "On-Demand",
                "id": "ON-DEMAND",
                "description": "",
                "href": 'https://docs.aws.amazon.com/es_es/AWSEC2/latest/UserGuide/ec2-on-demand-instances.html',
                "type": "CHECK",
                "recommendationLevel": 0
            },
            {
                "name": "Spot",
                "id": "SPOT",
                "description": "",
                "href": 'https://docs.aws.amazon.com/es_es/AWSEC2/latest/UserGuide/using-spot-instances.html',
                "type": "CHECK",
                "recommendationLevel": 0
            }
        ],
    }
]