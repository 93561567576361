export const KARPENTER = [
    {
        "name": "Karpenter configuration",
        "description": "What Karpenter features and configurations are you using?",
        "dependsOn": ["KARPENTER"],
        "selectionType": "multi",
        "options": [
            {
                "name": "Karpenter version",
                "id": "",
                "description": "",
                "href": "",
                "type": "INPUT",
                "recommendationLevel": 0
            },
            {
                "name": "NodePools",
                "id": "",
                "description": "Are you using one or multiple NodePools?",
                "href": "https://karpenter.sh/docs/concepts/nodepools/",
                "type": "INPUT",
                "recommendationLevel": 0
            },
            {
                "name": "Consolidation policy",
                "id": "",
                "description": "Are you expiring nodes when they are empty, or underutilised?",
                "href": "https://karpenter.sh/docs/concepts/disruption/#consolidation",
                "type": "INPUT",
                "recommendationLevel": 1
            },
            {
                "name": "Node expiration",
                "id": "",
                "description": "Are you expiring nodes after a certain amount of time?",
                "href": "https://karpenter.sh/docs/concepts/disruption/#automated-methods",
                "type": "CHECK",
                "recommendationLevel": 1
            },
            {
                "name": "Budgets",
                "id": "",
                "description": "Have you configured a budget or a schedule for the deprovisioning?",
                "href": "https://karpenter.sh/docs/concepts/disruption/#disruption-budgets",
                "type": "CHECK",
                "recommendationLevel": 0
            },
            {
                "name": "Cluster limits",
                "id": "",
                "description": "Have you set resource limits at the cluster level?",
                "href": "https://karpenter.sh/docs/concepts/nodepools/#speclimits",
                "type": "CHECK",
                "recommendationLevel": 0
            }
        ]
    }
]