import {Box, Button, ColumnLayout, Container, Header, SpaceBetween} from "@cloudscape-design/components";
import {getItemColor} from "../../utils/workloadUtils";

export default function ReviewWizard({steps, navigateToStep}) {
    return (
        <SpaceBetween direction={"vertical"} size={'xl'}>
            {steps.map(step => (
                <Container
                    header={
                        <Header
                            variant={"h3"}
                            description={step.description}
                            actions={
                                <Button onClick={() => navigateToStep(step.index)}>Edit</Button>
                            }
                        >{step.title}</Header>
                    }
                >
                    <ColumnLayout columns={Math.min(step.values.length, 3)} variant="text-grid">
                        {step.values.map(value => (
                            <div>
                                <Box variant="awsui-key-label" color={getItemColor(value)}>{value.name}</Box>
                                <Box variant="p">{value.value}</Box>
                            </div>
                        ))}
                    </ColumnLayout>
                </Container>
            ))}
        </SpaceBetween>
    )
}