export const workloads = [
  {
    "name": "Containers",
    "deploymentOptions": [
      {
        "id": "ECS",
        "recommendationLevel": 0,
        "description": "Native containers on AWS"
      },
      {
        "id": "EKS",
        "recommendationLevel": 0,
        "description": "Managed Kubernetes"
      },
      {
        "id": "K8S",
        "recommendationLevel": 0,
        "description": ""
      }
    ]
  },
  {
    "name": "Web application",
    "deploymentOptions": [
      {
        "id": "ASG",
        "recommendationLevel": 1,
        "description": ""
      },
      {
        "id": "EC2_F",
        "recommendationLevel": 0,
        "description": ""
      }
    ]
  },
  {
    "name": "CI-CD",
    "deploymentOptions": [
      {
        "id": "ASG",
        "recommendationLevel": 0,
        "description": ""
      },
      {
        "id": "EC2_F",
        "recommendationLevel": -1,
        "description": ""
      }
    ]
  },
  {
    "name": "Analytics",
    "deploymentOptions": [
      {
        "id": "EMR",
        "recommendationLevel": 1,
        "description": "Managed"
      },
      {
        "id": "EC2_F",
        "recommendationLevel": 0,
        "description": "Unmanaged"
      },
      {
        "id": "ASG",
        "recommendationLevel": -1,
        "description": "Unmanaged"
      },
      {
        "id": "EKS",
        "recommendationLevel": 0,
        "description": "EMR on EKS"
      }
    ]
  },
  {
    "name": "Batch jobs",
    "deploymentOptions": [
      {
        "id": "BATCH",
        "recommendationLevel": 1,
        "description": "Managed"
      },
      {
        "id": "EC2_F",
        "recommendationLevel": 0,
        "description": "Unmanaged"
      },
      {
        "id": "ASG",
        "recommendationLevel": -1,
        "description": "Unmanaged"
      }
    ]
  },
  {
    "name": "AI-ML",
    "deploymentOptions": [
      {
        "id": "BATCH",
        "recommendationLevel": 0,
        "description": "Managed"
      },
      {
        "id": "EC2_F",
        "recommendationLevel": 0,
        "description": "Unmanaged"
      },
      {
        "id": "ASG",
        "recommendationLevel": -1,
        "description": "Unmanaged"
      },
      {
        "id": "SAGEMAKER",
        "recommendationLevel": 1,
        "description": "Managed"
      }
    ]
  }
]
