export const ecs = {
    "name": "Amazon ECS",
    "steps": [
        {
            "name": "Cluster infrastructure",
            "description": "On which infrastructure are you running your ECS cluster?",
            "dependsOn": [],
            "selectionType": "single",
            "options": [
                {
                    "name": "AWS Fargate",
                    "id": "FARGATE",
                    "description": "",
                    "href": "",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "Amazon EC2",
                    "id": "EC2",
                    "description": "",
                    "href": "",
                    "type": "CHECK",
                    "recommendationLevel": 0
                }
            ]
        }
    ]
}