import {Wizard} from "@cloudscape-design/components";
import {useNavigate, useParams} from "react-router-dom";
import {i18nStrings} from "./i18nStrings";
import {deployments} from "../flow_config_files/deployments";
import {useEffect, useState} from "react";
import StepContent from "./stepContent";
import ReviewWizard from "./reviewWizard";
import {SubmitFormModal} from "../submitForm/submitFormModal";


export const Content = () => {
    const navigate = useNavigate()
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [selectedStepOptions, setSelectedStepOptions] = useState({})
    const [inputValues, setInputValues] = useState({})
    const deployment = useParams()['deployment']
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        removeChecksFromInputCards()
    }, [activeStepIndex]);

    function removeChecksFromInputCards() {
        const elements = document.querySelectorAll('div[cardtype="INPUT"]')

        elements.forEach(e => {
            const headerNodeChildren = e.parentNode.parentNode.children

            for (const child of headerNodeChildren) {
                if (child.className.startsWith('awsui_selection-control')) child.remove()
            }
        })
    }

    function navigateToStep(index) {
        setActiveStepIndex(index)
    }

    function getSelectedOptions() {
        const stepsWithContent = []

        deployments[deployment].steps.forEach(s => {
            let values = []

            // Add checked options
            if (s.name in selectedStepOptions && selectedStepOptions[s.name].length > 0) {
                values = [...values, ...selectedStepOptions[s.name].map(o => ({'name': o.name, 'value': '', 'recommendationLevel': o.recommendationLevel}))]
            }

            // Add input values
            if (s.name in inputValues) {
                for (const [inputName, value] of Object.entries(inputValues[s.name])) {
                    if (value.trim() !== '') values.push({'name': inputName, 'value': value.trim()})
                }
            }

            if (values.length > 0) {
                stepsWithContent.push({
                    index: getIndexForActiveStep(s.name),
                    description: s.description,
                    title: s.name,
                    values: values
                })
            }
        })

        return stepsWithContent
    }

    function buildReviewStep(selectedOptions) {
        return {
            'title': 'Review and submit',
            'description': '',
            'content': <ReviewWizard steps={selectedOptions} navigateToStep={navigateToStep}></ReviewWizard>
        }
    }

    function getIndexForActiveStep(stepName) {
        const activeSteps = getActiveSteps()

        for (let i = 0; i < activeSteps.length; i++) {
            if (activeSteps[i].name === stepName) return i
        }
    }

    function getActiveSteps() {
        let steps = []

        deployments[deployment].steps.forEach(s => {
            if (s.dependsOn.length !== 0) {
                for (const [_, selectedOptions] of Object.entries(selectedStepOptions)) {
                    selectedOptions.forEach(o => {
                        if (s.dependsOn.includes(o.id)) {
                            steps.push(s)
                        }
                    })
                }
            }
            else {
                steps.push(s)
            }
        })

        steps.push({name: 'Review and submit'})

        return steps
    }

    function buildActiveSteps() {
        const steps = getActiveSteps()

        for (let i = 0; i < steps.length; i++) {
            if (i === steps.length - 1) steps[i] = buildReviewStep(getSelectedOptions())
            else steps[i] = buildStep(steps[i])
        }

        return steps
    }

    function buildStep(step) {
        return {
            'title': step['name'],
            'description': step['description'],
            'content':
                <StepContent
                    step={step}
                    optionSelectionDelegate={updateSelectedStepOptions}
                    inputChangeDelegate={inputValueChangedDelegate}
                    selectedOptions={selectedStepOptions[step.name]}
                    inputValues={inputValues[step.name]}>
                </StepContent>
        }
    }

    function updateSelectedStepOptions(stepName, selectedOptions) {
        const unselectedOption = selectedStepOptions[stepName]?.filter(x => !selectedOptions.includes(x))[0];

        const newOptions = {}
        newOptions[stepName] = selectedOptions

        const newInputValues = {}

        if (unselectedOption !== undefined) {
            // Find all the steps dependent on this option, and unselect all their options
            deployments[deployment].steps.forEach(s => {
                if (s.dependsOn.includes(unselectedOption.id)) {
                    newOptions[s.name] = []
                    newInputValues[s.name] = {}
                }
            })
        }

        setInputValues({...inputValues, ...newInputValues})
        setSelectedStepOptions({...selectedStepOptions, ...newOptions})
    }

    function inputValueChangedDelegate(stepName, inputName, value) {
        const newInputValue = {}
        newInputValue[inputName] = value

        let stepInputs

        if (stepName in inputValues) {
            stepInputs = {...inputValues[stepName], ...newInputValue}
        }
        else {
            stepInputs = newInputValue
        }

        const allStep = {}
        allStep[stepName] = stepInputs

        setInputValues({...inputValues, ...allStep})
    }

    return (
        <>
            <Wizard
                i18nStrings={i18nStrings}
                onNavigate={({ detail }) =>
                    setActiveStepIndex(detail.requestedStepIndex)
                }
                activeStepIndex={activeStepIndex}
                steps={buildActiveSteps()}
                onCancel={() => navigate('/')}
                onSubmit={() => setModalVisible(true)}
            ></Wizard>

            <SubmitFormModal
                visible={modalVisible}
                dismissDelegate={() => setModalVisible(false)}
                steps={getSelectedOptions()}
            ></SubmitFormModal>
        </>
    );
}