export const INSTANCE_TYPE_SELECTION = [
    {
        "name": "Instance type selection",
        "description": "How are you selecting instance types for launching EC2 capacity?",
        "dependsOn": [],
        "selectionType": "single",
        "options": [
            {
                "name": "Attribute-based instance type selection (ABIS)",
                "id": "",
                "description": "With attribute-based instance type selection, instead of providing a list of specific instance types, you provide a list of instance attributes that your instances require.",
                "href": 'https://docs.aws.amazon.com/autoscaling/ec2/userguide/create-mixed-instances-group-attribute-based-instance-type-selection.html',
                "type": "CHECK",
                "recommendationLevel": 1
            },
            {
                "name": "Manual",
                "id": "",
                "description": "If possible, specify which instance types or how many instance types you are using.",
                "href": "",
                "type": "INPUT",
                "recommendationLevel": 0
            },
        ]
    }
]