export function copyConfig(config) {
    const newList = []

    config.forEach(element => {
        const elementCopy = {...element}

        let options = []
        let dependsOn = []

        elementCopy.dependsOn.forEach(dep => {
            dependsOn.push(dep)
        })

        elementCopy.options.forEach(opt => {
            const optCopy = {...opt}
            options.push(optCopy)
        })

        elementCopy.options = options
        elementCopy.dependsOn = dependsOn

        newList.push(elementCopy)
    })

    return newList
}