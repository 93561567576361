import {Content} from "./content"
import {AppLayout} from "@cloudscape-design/components";
import CustomHelpPanel from "../customComponents/customHelpPanel";

export const Home = () => {
    return (
        <AppLayout
            navigationHide={true}
            tools={<CustomHelpPanel/>}
            content={<Content/>}
            disableContentPaddings={false}
            contentType={"cards"}
        />
    )
}