import {INSTANCE_TYPE_SELECTION} from "./generics/instance_type_selection";
import {PURCHASING_OPTIONS} from "./generics/purchasing_options";
import {ALLOC_STRATEGIES} from "./generics/alloc_strategies";
import {RESERVATIONS} from "./generics/reservations";
import {SPOT_SIGNALS} from "./generics/spot_signals";

export const asg = {
  "name": "Auto Scaling group",
  "steps": [
      ...INSTANCE_TYPE_SELECTION,
      ...PURCHASING_OPTIONS,
      ...ALLOC_STRATEGIES,
      ...SPOT_SIGNALS,
      ...RESERVATIONS,
    {
      "name": "Auto Scaling group configuration",
      "description": "Which Auto Scaling features and configurations are you using?",
      "dependsOn": [],
      "selectionType": "multi",
      "options": [
        {
          "name": "Instance maintenance policy",
          "id": "",
          "description": "What instance maintenance policy are you using?",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/ec2-auto-scaling-instance-maintenance-policy.html",
          "type": "INPUT",
          "recommendationLevel": 0
        },
        {
          "name": "Scale-in protection",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/ec2-auto-scaling-instance-protection.html",
          "type": "CHECK",
          "recommendationLevel": 0
        },
        {
          "name": "Launch template",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/launch-templates.html",
          "type": "CHECK",
          "recommendationLevel": 0
        },
        {
          "name": "Warm pools",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/ec2-auto-scaling-warm-pools.html",
          "type": "CHECK",
          "recommendationLevel": 0
        },
        {
          "name": "Launch configuration",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/create-auto-scaling-groups-launch-configuration.html",
          "type": "CHECK",
          "recommendationLevel": -1
        },
        {
          "name": "Dynamic scaling",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/as-scale-based-on-demand.html",
          "type": "CHECK",
          "recommendationLevel": 0
        },
        {
          "name": "Scheduled scaling",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/ec2-auto-scaling-scheduled-scaling.html",
          "type": "CHECK",
          "recommendationLevel": 0
        },
        {
          "name": "Predictive scaling",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/ec2-auto-scaling-predictive-scaling.html",
          "type": "CHECK",
          "recommendationLevel": 1
        },
        {
          "name": "Min / max / desired capacity",
          "id": "",
          "description": "",
          "href": "https://docs.aws.amazon.com/autoscaling/ec2/userguide/asg-capacity-limits.html",
          "type": "INPUT",
          "recommendationLevel": 0
        }
      ]
    }
  ]
}