import {Alert, Box, Button, FormField, Input, Modal, SpaceBetween} from "@cloudscape-design/components";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {postForm} from "../../utils/api";

export const SubmitFormModal = ({visible, dismissDelegate, steps}) => {
    const [customer, setCustomer] = useState('')
    const [recipients, setRecipients] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [submissionResult, setSubmissionResult] = useState(null)

    const workload = useParams()['workload']
    const deployment = useParams()['deployment']

    async function submitForm() {
        setSubmissionResult(null)
        setSubmitting(true)

        const params = {steps: steps, workload: workload, deployment: deployment, customer: customer, recipients: recipients}

        try {
            await postForm(params)
            setSubmissionResult({type: 'success', title: 'Form successfully sent!', message: 'You may now dismiss this window and start a new form.'})
        }
        catch (e) {setSubmissionResult({type: 'error', title: 'Could not submit form', message: e.message})}
        finally {setSubmitting(false)}
    }

    function resetAndDismiss() {
        dismissDelegate()
        setSubmitting(false)
        setSubmissionResult(null)
        setCustomer('')
        setRecipients('')

    }

    return <Modal
        size={'medium'}
        visible={visible}
        onDismiss={() => resetAndDismiss()}
        header={'Submit form'}
        footer={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" disabled={submitting} onClick={() => resetAndDismiss()}>Cancel</Button>
                    <Button variant="primary" onClick={() => submitForm()} disabled={submitting || customer.trim() === '' || recipients.trim() === ''} loading={submitting}>Submit</Button>
                </SpaceBetween>
            </Box>
        }
    >
        <SpaceBetween direction={"vertical"} size={'l'}>
            {submissionResult !== null &&
                <Alert
                    header={submissionResult.title}
                    type={submissionResult.type}
                >
                    {submissionResult.message}
                </Alert>
            }

            <FormField
                label="Customer name"
            >
                <Input
                    placeholder={'Enter the customer name to which this form belongs'}
                    value={customer}
                    onChange={({detail}) => setCustomer(detail.value)}
                    inputMode={'text'}
                    type={'text'}
                >
                </Input>
            </FormField>

            <FormField
                label={'Recipients\' e-mail addresses'}
            >
                <Input
                    placeholder={'Enter the recipients\' e-mail addresses separated by commas'}
                    value={recipients}
                    onChange={({detail}) => setRecipients(detail.value)}
                    inputMode={'text'}
                    type={'text'}
                >
                </Input>
            </FormField>
        </SpaceBetween>
    </Modal>
}