import {Route, Routes} from "react-router-dom"
import {Home} from "../components/home"
import {NotFound} from "../components/notFound"
import {WorkloadWizard} from "../components/workloadWizard";


export const PageRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/:workload/:deployment' element={<WorkloadWizard/>} />
            <Route path='*' element={<NotFound/>} />
        </Routes>
    )
}