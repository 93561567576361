import {SPOT_SIGNALS} from "./generics/spot_signals";
import {INSTANCE_TYPE_SELECTION} from "./generics/instance_type_selection";
import {PURCHASING_OPTIONS} from "./generics/purchasing_options";
import {ALLOC_STRATEGIES} from "./generics/alloc_strategies";
import {RESERVATIONS} from "./generics/reservations";
import {copyConfig} from "../../utils/copy";
import {KARPENTER} from "./generics/karpenter";
import {CAS} from "./generics/cas";

const CPY_INSTANCE_TYPE_SELECTION = copyConfig(INSTANCE_TYPE_SELECTION)
CPY_INSTANCE_TYPE_SELECTION[0].dependsOn = ['SELF-MNG_NODES']

const CPY_PURCHASING_OPTIONS = copyConfig(PURCHASING_OPTIONS)
CPY_PURCHASING_OPTIONS[0].dependsOn = ['SELF-MNG_NODES', 'CAS']

export const eks = {
    "name": "Amazon EKS",
    "steps": [
        {
            "name": "Autoscaling configuration",
            "description": "What scaling product are you using to scale your resources?",
            "dependsOn": [],
            "selectionType": "single",
            "options": [
                {
                    "name": "Karpenter",
                    "id": "KARPENTER",
                    "description": "Karpenter is a flexible, high-performance Kubernetes cluster autoscaler that helps improve application availability and cluster efficiency.",
                    "href": "https://karpenter.sh/docs/",
                    "type": "CHECK",
                    "recommendationLevel": 1
                },
                {
                    "name": "Cluster Autoscaler",
                    "id": "CAS",
                    "description": "The Kubernetes Cluster Autoscaler automatically adjusts the number of nodes in your cluster when pods fail or are rescheduled onto other nodes.",
                    "href": "https://github.com/kubernetes/autoscaler/blob/master/cluster-autoscaler/cloudprovider/aws/README.md",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "Self-managed nodes",
                    "id": "SELF-MNG_NODES",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/eks/latest/userguide/worker.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "AWS Fargate",
                    "id": "FARGATE",
                    "description": "",
                    "href": "https://docs.aws.amazon.com/eks/latest/userguide/fargate.html",
                    "type": "CHECK",
                    "recommendationLevel": 0
                },
                {
                    "name": "Other",
                    "id": "",
                    "description": "",
                    "href": "",
                    "type": "INPUT",
                    "recommendationLevel": 0
                }
            ]
        },
        ...KARPENTER,
        ...CAS,
        {
            "name": "Self-managed nodes configuration",
            "description": "How have you configured your node groups?",
            "dependsOn": ["SELF-MNG_NODES"],
            "selectionType": "multi",
            "options": [
                {
                    "name": "Amount of node groups",
                    "id": "",
                    "href": "",
                    "description": "How many node groups are you using?",
                    "type": "INPUT"
                },
                {
                    "name": "Node group scaling",
                    "id": "",
                    "href": "",
                    "description": "What technology are you using to scale your node groups?",
                    "type": "INPUT"
                }
            ]
        },
        ...CPY_INSTANCE_TYPE_SELECTION,
        ...CPY_PURCHASING_OPTIONS,
        ...ALLOC_STRATEGIES,
        ...SPOT_SIGNALS,
        ...RESERVATIONS
    ]
}