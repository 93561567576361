const INVOKE_URL = 'https://0u1boeu5d9.execute-api.eu-west-1.amazonaws.com/prod/'

async function performRequest(url, method='GET', body=null, queryParams: {} = null, headers = {}) {
    const reqOptions = {
        'method': method,
        'headers': headers
    }

    if (body !== null) reqOptions['body'] = JSON.stringify(body)

    if (queryParams !== null) url += ('?' + new URLSearchParams(queryParams))

    const response = await fetch(url, reqOptions)

    return handleResponse(response)
}

async function handleResponse(response) {
    if (!response.ok) {
        const errorMessage = await response.text()
        throw new Error(errorMessage)
    }
    else if (response.status === 204) return {}
    else if (response.ok) return response.json()
}

export function postForm(form) {
    const url = INVOKE_URL + 'forms'
    return performRequest(url, 'POST', form)
}